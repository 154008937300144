<template>
  <img class="spinner" :class="{'dark-theme': darkTheme}" src="../assets/spinner-solid.svg" alt="Loading spinner" />
</template>

<script>
export default {
  name: 'SmallLoadingSpinner',
  functional: true,
  props: {
    darkTheme: Boolean
  }
};
</script>

<style lang="scss" scoped>
.spinner {
  width: 3rem;
  height: 3rem;
  animation: spin-clockwise 1.5s linear infinite;

  &.dark-theme {
    filter: brightness(.5);
  }
}

@keyframes spin-clockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
