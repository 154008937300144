// TODO: this feels more confusing than it should imo,
// consider using a nested object structure

/* maybe...
const urlsB = {
  path: 'http://hello.com',
  stories() {
    return {
      path: `${this.path}/stories`,
      story(id) {
        return {
          path: `${this.path}/${id}`,
          currentChallenge() {
            return {
              path: `${this.path}/current-challenge`
            }
          }
        }
      }
    }
  }
  */

function getServerUrl() {
  return process.env.VUE_APP_BACKEND_URL;
}

export function getUsersServiceRoot() {
  return `${getServerUrl()}/users`;
}

export function getUsersServicePath(username) {
  return `${getUsersServiceRoot()}/${username}`;
}

export function getUserCreatedPath(username) {
  return `${getUsersServicePath(username)}/created`;
}

function getPageQuery(pageNumber, pageSize) {
  return `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
}

export function getStoriesServicePath() {
  return `${getServerUrl()}/games`;
}

function getStoryServicePath(storyId) {
  return `${getStoriesServicePath()}/${storyId}`;
}

export function getStoryContentsPath(storyId) {
  return `${getStoryServicePath(storyId)}/contents`;
}

export function getStoriesForUserPath(username, pageNumber, pageSize) {
  return `${getUsersServicePath(username)}/games${getPageQuery(pageNumber, pageSize)}`;
}

export function getNotificationsForUserPath(username, pageNumber, pageSize) {
  return `${getUsersServicePath(username)}/notifications${getPageQuery(pageNumber, pageSize)}`;
}

export function getUnreadNotificationsCountForUserPath(username) {
  return `${getUsersServicePath(username)}/notifications/unread-count`;
}

export function getIncompleteStoriesForUserWhereItsThisUsersTurnPath(username, pageNumber, pageSize) {
  return `${getStoriesForUserPath(
    username,
    pageNumber,
    pageSize
  )}&completed=false&thisUsersTurn=true`;
}

export function getCompletedStoriesForUserPath(username, pageNumber, pageSize) {
  return `${getStoriesForUserPath(
    username,
    pageNumber,
    pageSize
  )}&completed=true`;
}

export function getIncompleteStoriesForUserWhereItsNotThisUsersTurnPath(
  username,
  pageNumber,
  pageSize
) {
  return `${getStoriesForUserPath(
    username,
    pageNumber,
    pageSize
  )}&completed=false&thisUsersTurn=false`;
}

export function getDeclineStoryPath(storyid, username) {
  return `${getStoryServicePath(storyid)}/users/${username}`;
}

export function getStoryRoundValidationPath(storyId) {
  return `${getStoriesServicePath()}/validator/${storyId}/rounds/latest`;
}

export function getStoryRoundSubmissionPath(storyId) {
  return `${getStoryServicePath(storyId)}/rounds/latest`;
}

export function getAddStoryRoundIllustrationPath(storyId) {
  return `${getStoryRoundSubmissionPath(storyId)}/illustration`;
}

export function getFriendsServicePath(username) {
  return `${getUsersServicePath(username)}/friends`;
}

export function getFriendRequestsServicePath(username) {
  return `${getUsersServicePath(username)}/friend-requests`;
}

export function getFriendRequestsReceivedByUserPath(username, pageNumber, pageSize) {
  return `${getFriendRequestsServicePath(username)}${getPageQuery(
    pageNumber,
    pageSize
  )}&type=received&rejected=false`;
}

export function getFriendRequestsSentByUserPath(username, pageNumber, pageSize) {
  return `${getFriendRequestsServicePath(username)}${getPageQuery(
    pageNumber,
    pageSize
  )}&type=sent&rejected=false`;
}

export function getFriendRequestsRejectedByUserPath(username, pageNumber, pageSize) {
  return `${getFriendRequestsServicePath(username)}${getPageQuery(
    pageNumber,
    pageSize
  )}&type=received&rejected=true`;
}

export function getFriendRequestsEligibilityPath(username, friendUsername) {
  return `${getUsersServicePath(username)}/friend-request-eligibility/${friendUsername}`;
}

export function getRemoveFriendPath(username, friendUsername) {
  return `${getFriendsServicePath(username)}/${friendUsername}`;
}

export function getFriendRequestPath(username, friendRequestId) {
  return `${getFriendRequestsServicePath(username)}/${friendRequestId}`;
}

export function getCurrentChallengePath(storyId) {
  return `${getStoriesServicePath()}/${storyId}/current-challenge`;
}

export function getStoryPlagiarismPath() {
  return `${getServerUrl()}/plagiarized-line`;
}
