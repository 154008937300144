import { createRouter, createWebHistory } from 'vue-router';
import InProgressStories from '../views/InProgressStories.vue';
import unsavedChangesStateStore from '../services/unsavedChangesStateStore';

const routes = [
  {
    path: '/',
    name: 'In Progress',
    component: InProgressStories,
  },
  {
    path: '/stories/completed',
    name: 'Completed',
    component: () => import(/* webpackChunkName: "completed" */ '../views/CompletedStories.vue'),
  },
  {
    path: '/stories/in-progress/:storyId',
    name: 'Contribute to a story',
    component: () => import(/* webpackChunkName: "contribute" */ '../views/Contribute.vue'),
  },
  {
    path: '/create-story',
    name: 'Create a Story',
    component: () => import(/* webpackChunkName: "create" */ '../views/CreateStory.vue'),
  },
  {
    path: '/friends',
    name: 'Friends',
    component: () => import(/* webpackChunkName: "friends" */ '../views/Friends.vue'),
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import(/* webpackChunkName: "friends" */ '../views/Notifications.vue'),
  },
  {
    path: '/stories/completed/:storyId',
    name: 'Completed Story',
    component: () => import(/* webpackChunkName: "completedStory" */ '../views/CompletedStory.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// This is a little hacky and makes me think it might be a good idea to use Vuex
// When the user attempts to navigate through a router link, we check whether we have
// unsaved changes. If we do, we display a prompt and store the next() function
// in our state store. This allows us to defer routing until the user clicks "ok" on the modal,
// or cancel if they click cancel. See the app component for the modal code.
router.beforeEach((to, from, next) => {
  if (unsavedChangesStateStore.state.unsavedChanges) {
    unsavedChangesStateStore.displayUnsavedChangesPrompt();
    unsavedChangesStateStore.setRouterNext(next.bind(this));
  } else {
    next();
  }
});

export default router;
