<template>
  <div class="modal-button-wrapper">
    <IconButton
      @clicked="openModal()"
      :ariaLabel="buttonAriaLabel"
      :buttonTitle="buttonTitle"
      :iconType="iconType"
    />
    <Modal
      :title="title"
      :showSpinner="performingAction"
      :open="open"
      :hideContents="!showModalContents"
      :hideErrors="hideErrors"
      :errorMessage="errorMessage"
      @okButtonClicked="handleModalOkClicked()"
      @cancelButtonClicked="closeModal()"
    >
      <slot></slot>
    </Modal>
  </div>
</template>

<script>
import { nextTick } from 'vue';

import modalStateStore from '../services/modalStateStore';
import Modal from './Modal.vue';
import IconButton from './IconButton.vue';
import { showSuccess } from '../services/toastService';

export default {
  name: 'ModalButton',
  components: {
    IconButton,
    Modal
  },
  props: {
    title: String,
    okButtonLabel: String,
    cancelButtonLabel: String,
    onOkClicked: Function,
    successMessage: String,
    onSuccess: Function,
    buttonTitle: String,
    buttonAriaLabel: String,
    iconType: String,
    okDisabled: Boolean,
    focusCancelOnRender: {
      type: Boolean,
      default: true,
    },
    hideErrors: Boolean,
  },
  emits: ['okSuccess', 'errorOccurred'],
  data() {
    return {
      open: false,
      showModalContents: false,
      performingAction: false,
      focusCancelButton: false,
      errorMessage: null,
    };
  },
  computed: {
    okButtonDisabled() {
      return !this.open || this.okDisabled || this.performingAction;
    },
    cancelButtonDisabled() {
      return !this.open || this.performingAction;
    },
    okButtonTitle() {
      return this.okDisabled
        ? "Fill out the form with valid input and then we'll get to clicking this bad boy :)"
        : null;
    },
  },
  methods: {
    openModal() {
      modalStateStore.open();
      this.open = true;
      this.showModalContents = true;

      if (this.focusCancelOnRender) {
        nextTick().then(() => {
          this.focusCancelButton = true;
        });
      }
    },
    closeModal() {
      modalStateStore.close();
      this.open = false;

      if (this.focusCancelOnRender) {
        this.focusCancelButton = false;
      }

      // TODO: find a different way to do this because this is hacky
      // Since the slotted contents need to be re-rendered every time we open the modal,
      // we use v-if to conditionally render them. However, sicne we use a 400ms transition
      // when the modal is closed, this causes the slot contents to disappear awkwardly. This
      // fixes that, but I'm not proud of it.
      setTimeout(() => {
        this.showModalContents = false;
      }, 400);
    },
    handleModalOkClicked() {
      this.performingAction = true;
      this.errorMessage = null;

      this.onOkClicked()
        .then(() => {
          this.handleOkSuccess();
        })
        .catch(error => {
          this.$emit('errorOccurred', error);
          this.errorMessage = error.message;
        })
        .finally(() => {
          this.performingAction = false;
        });
    },
    handleOkSuccess() {
      this.closeModal();

      if (this.successMessage) {
        showSuccess(this.successMessage);
      }

      this.$emit('okSuccess');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-button-wrapper {
  display: flex;
}
</style>
