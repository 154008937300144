import { fetchService } from './betterFetch';
import {
  getFriendsServicePath,
  getFriendRequestsReceivedByUserPath,
  getFriendRequestsServicePath,
  getFriendRequestsEligibilityPath,
  getRemoveFriendPath,
  getFriendRequestsRejectedByUserPath,
  getFriendRequestPath,
  getFriendRequestsSentByUserPath
} from './urlService';

export async function getFriendsForUser(username) {
  return fetchService.fetch(getFriendsServicePath(username));
}

export async function getFriendRequestsReceivedByUser(username, pagenumber, pageSize) {
  return fetchService.fetch(
    getFriendRequestsReceivedByUserPath(username, pagenumber, pageSize)
  );
}

export async function getFriendRequestsSentByUser(username, pagenumber, pageSize) {
  return fetchService.fetch(getFriendRequestsSentByUserPath(username, pagenumber, pageSize));
}

export async function getFriendRequestsRejectedByUser(username, pagenumber, pageSize) {
  return fetchService.fetch(getFriendRequestsRejectedByUserPath(username, pagenumber, pageSize));
}

export async function sendFriendRequest(username, friendUsername) {
  return fetchService.fetch(getFriendRequestsServicePath(username), {
    method: 'POST',
    body: {
      receiver: friendUsername,
    },
  });
}

export async function validateFriendRequestUsername(username, friendUsername) {
  return fetchService.fetch(getFriendRequestsEligibilityPath(username, friendUsername.toLowerCase()));
}

export async function removeFriend(username, friendUsername) {
  return fetchService.fetch(getRemoveFriendPath(username, friendUsername), {
    method: 'DELETE',
  });
}

export async function modifyFriendRequest(username, body) {
  return fetchService.fetch(getFriendRequestPath(username, body.id), {
    method: 'PUT',
    body,
  });
}

export function addFriend(username, friendUsername) {
  return fetchService.fetch(getFriendsServicePath(username), {
    method: 'POST',
    body: {
      username: friendUsername,
    },
  });
}
