<template>
  <transition name="slide-in" mode="out-in">
    <TabContent :loading="initialLoadHasNotCompleted" :errorOccurred="errorOccurred">
      <InfiniteScroll
        v-show="initialLoadHasCompleted"
        :getFnc="getStoriesThisUsersTurnFnc"
        :showInitialLoadingIndicator="false"
        class='your-turn-list'
        @dataChange="handleUserStoriesUpdated($event)"
        @errorOccurred="handleError()"
        errorMessage="'Uh oh! An error occurred while fetching your stories. 😮'"
      >
        <transition-group name="story">
          <router-link
            v-for="story in usersStories"
            :key="story.id"
            :to="`/stories/in-progress/${story.id}`"
          >
            <div class="in-progress-story your-turn">
              <span class="title">{{ story.name }}</span>
              <ModalButton
                v-if="thisUserHasNotParticipated(story)"
                :ariaLabel="`Decline '${story.name}'`"
                :buttonTitle="`Decline '${story.name}'`"
                :title="`Decline '${story.name}'?`"
                :onOkClicked="declineStory.bind(this, $username, story.id)"
                @okSuccess="handleDeclinedSuccessfully(story)"
                :successMessage="`'${story.name}' declined`"
                >{{ getModalMessage(story) }}
              </ModalButton>
              <span class="current-user">Your turn!</span>
              <span
                class="remaining-turns"
                :title="`${getTurnsRemaining(story)} turns remaining`"
                >{{ getTurnsRemaining(story) }}</span
              >
            </div>
          </router-link>
        </transition-group>
      </InfiniteScroll>
      <h3 v-if="userHasStoriesWaiting">Stories waiting on others:</h3>
      <InfiniteScroll
        v-show="initialLoadHasCompleted"
        :getFnc="getStoriesNotThisUsersTurnFnc"
        @dataChange="handleOtherStoriesUpdated($event)"
        @errorOccurred="handleError()"
        :showInitialLoadingIndicator="false"
        errorMessage="'Uh oh! An error occurred while fetching your stories. 😮'"
      >
        <transition-group name="story">
          <div class="in-progress-story" v-for="story in otherStories" :key="story.id" @click="handleWaitingStoryClicked(story.currentPlayerUsername)">
            <span class="title">{{ story.name }}</span>
            <span class="current-user" :title="`We're waiting on ${story.currentPlayerUsername}`">{{
              story.currentPlayerUsername
            }}</span>
            <span
              class="remaining-turns"
              :aria-label="`${getTurnsRemaining(story)} turns remaining`"
              :title="`${getTurnsRemaining(story)} turns remaining`"
              >{{ getTurnsRemaining(story) }}</span
            >
          </div>
        </transition-group>
      </InfiniteScroll>
      <transition name="no-stories-found">
        <MysticalObject
          v-if="noStoriesFound"
          type="thinking"
          message="You don't have any stories yet."
        >
          <MysticalButton to="/create-story" label="create a story" />
        </MysticalObject>
      </transition>
    </TabContent>
  </transition>
</template>

<script>
import TabContent from '../components/TabContent.vue';
import InfiniteScroll from '../components/InfiniteScroll.vue';
import ModalButton from '../components/ModalButton.vue';
import MysticalButton from '../components/MysticalButton.vue';
import MysticalObject from '../components/MysticalObject.vue';
import {
  declineStory,
  getInProgressStoriesWhereItsThisUsersTurn,
  getInProgressStoriesWhereItsNotThisUsersTurn,
} from '../services/storiesService';
import { showInfo } from '../services/toastService';

const DECLINE_WARNING =
  "Are you sure you want to decline this story? Keep in mind that this can't be undone!";
const LAST_PLAYER_WARNING =
  'Since you are the only other player remaining, this will end the story.';

export default {
  name: 'InProgressStories',
  components: {
    TabContent,
    InfiniteScroll,
    ModalButton,
    MysticalButton,
    MysticalObject,
  },
  data() {
    return {
      usersStories: null,
      otherStories: null,
      errorOccurred: false,
      getStoriesThisUsersTurnFnc: getInProgressStoriesWhereItsThisUsersTurn,
      getStoriesNotThisUsersTurnFnc: getInProgressStoriesWhereItsNotThisUsersTurn,
      declineStory,
    };
  },
  computed: {
    initialLoadHasNotCompleted() {
      return this.usersStories === null || this.otherStories === null;
    },
    initialLoadHasCompleted() {
      return this.usersStories !== null && this.otherStories !== null;
    },
    noStoriesFound() {
      return (
        this.initialLoadHasCompleted &&
        this.usersStories.length === 0 &&
        this.otherStories.length === 0
      );
    },
    userHasStoriesWaiting() {
      return this.otherStories && this.otherStories.length > 0;
    }
  },
  methods: {
    getTurnsRemaining(story) {
      return story.totalRoundCount - story.currentRoundNumber + 1;
    },
    thisUserHasNotParticipated(story) {
      const storyUser = story.users.find(user => user.id.username === this.$username);

      return !storyUser.userHasParticipated;
    },
    getModalMessage(story) {
      const playersRemaining = story.users.filter(user => user.declined === false);

      return playersRemaining.length === 2
        ? `${DECLINE_WARNING} ${LAST_PLAYER_WARNING}`
        : DECLINE_WARNING;
    },
    handleDeclinedSuccessfully(storyToDecline) {
      this.usersStories = this.usersStories.filter(story => story.id !== storyToDecline.id);
    },
    handleUserStoriesUpdated(newStoryList) {
      this.usersStories = newStoryList;
    },
    handleOtherStoriesUpdated(newStoriesList) {
      this.otherStories = newStoriesList;
    },
    handleError() {
      this.errorOccurred = true;
    },
    handleWaitingStoryClicked(username) {
      showInfo(`This story is waiting on ${username} — we'll let you know when it's your turn!`);
    }
  },
};
</script>

<style lang="scss" scoped>
a {
  &:hover {
    .in-progress-story.your-turn {
      transform: scale(1.01);
      box-shadow: var(--purple-shadow);
    }
  }
}

h3 {
  margin-top: 0;
}

.your-turn-list {
  margin-bottom: 1rem;
}

.in-progress-story {
  display: flex;
  background-color: var(--color-purple-darken-1);
  padding: 1rem 2rem;
  font-size: 2rem;
  border-radius: 1.5rem;
  margin-bottom: 1rem;
  align-items: center;

  & > .current-user {
    margin-left: auto;
  }
  &.your-turn {
    background-color: var(--color-purple-lighten-1);
    transition: transform 400ms ease, box-shadow 400ms ease;

    .remaining-turns {
      background: var(--color-creamsicle);
      color: var(--color-purple-darken-1);
      cursor: pointer;
    }
  }

  .remaining-turns {
    width: 3rem;
    height: 3rem;
    background: var(--color-purple);
    margin-left: 1rem;
    border-radius: 50%;
    color: var(--color-purple-lighten-2);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    cursor: default;
  }
}

.mystical-object {
  .button-wrapper {
    display: inline-block;
    margin: 1rem;
  }
}

.story-enter-active,
.story-leave-active {
  transition: opacity 400ms ease, transform 400ms ease;
}

.story-enter-from,
.story-leave-to {
  opacity: 0;
  transform: translateY(3rem);
}

.no-stories-found-leave-active,
.no-stories-found-enter-active {
  transition: transform 400ms ease, opacity 400ms ease;
}

.no-stories-found-leave-to,
.no-stories-found-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

@media (max-width: 650px) {
  .in-progress-story {
    font-size: 1.5rem;
  }
}

@media (max-width: 500px) {
  .in-progress-story {
    font-size: 1.2rem;
  }
}
</style>
