import { fetchService } from './betterFetch';
import { getUsersServiceRoot, getUserCreatedPath } from './urlService';

export function exists(username) {
  return fetchService.fetch(getUserCreatedPath(username));
}

export function create(username) {
  return fetchService.fetch(getUsersServiceRoot(), {
    method: 'POST',
    body: {
      username,
    },
  });
}
