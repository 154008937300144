/**
 *  Who needs Axios 😉 -- a wrapper for fetch which properly rejects promises if the status code
 *  isn't a 200, and returns actual JSON.
 *
 *  Note that the promise rejection logic is specific to the backend error object shape.
 * */
function statusCodeIsNotInSuccessRange(statusCode) {
  return Math.floor(statusCode / 100) !== 2;
}

function getMessagesOrDefault(resultsJSON) {
  return resultsJSON.messages ? resultsJSON.messages.join(' ') : 'Something has gone very wrong!';
}

async function betterFetch(url, options) {
  const optionsWithBodyStringified = { ...options };

  if (options.body && !options.multipart) {
    optionsWithBodyStringified.body = JSON.stringify(options.body);
    optionsWithBodyStringified.headers['Content-Type'] = 'application/json';
  }

  try {
    const results = await fetch(url, optionsWithBodyStringified);

    const resultsParsed = options.multipart ? results : await results.json();

    if (statusCodeIsNotInSuccessRange(results.status)) {
      return Promise.reject({ status: results.status, message: getMessagesOrDefault(resultsParsed) });
    }

    return resultsParsed;
  } catch (error) {
    return Promise.reject(error);
  }
}

// This token argument is curried, allowing us to set it for all fetch requests that use
// this service. This is done when the user successfully authenticates (see main.js).
// This prevents us from having to pass the token along every time we want to call a service function
const fetchWithToken = token => async (url, options) => {
  const optionsWithAuthorizationHeader = { ...options };
  const authorizationValue = `Bearer ${token}`;

  if (optionsWithAuthorizationHeader.headers) {
    optionsWithAuthorizationHeader.headers.Authorization = authorizationValue;
  } else {
    optionsWithAuthorizationHeader.headers = {
      Authorization: authorizationValue,
    };
  }

  return betterFetch(url, optionsWithAuthorizationHeader);
};

export const fetchService = {
  setToken(token) {
    this.fetch = fetchWithToken(token);
  },
  fetch() {
    console.error('Fetch was called before a token was set!');
  }
};
