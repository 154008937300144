import { reactive } from 'vue';

export default reactive({
  state: {
    newNotificationCount: 0,
  },

  increaseNotificationCount(amount) {
    this.state.newNotificationCount += amount;
  },
  clearNotificationCount() {
      this.state.newNotificationCount = 0;
  }
});
