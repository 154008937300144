<template>
  <img
    class="async-validation-success-indicator"
    src="../assets/check-circle-solid.svg"
    alt="Validation success icon"
  />
</template>

<script>
export default {
    name: 'SuccessIcon',
    functional: true
};
</script>
