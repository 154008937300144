<template>
  <div id="app-wrapper" @click="handleClick($event)">
    <header>
      <router-link to="/" class="home-link-container">
        <div id="logo"><span id="logo-text">m</span></div>
        <div id="logo-cover" aria-hidden="true"><span id="logo-text-cover">m</span></div>
      </router-link>
      <div
        class="notifications header-item"
        @mouseenter="handleNotificationMenuMouseOver()"
        @mouseleave="handleNotificationMenuMouseOut()"
      >
        <img
          class="header-icon notification-menu-icon"
          src="./assets/bell-solid.svg"
          alt="Notifications icon"
        />
        <div
          v-show="notificationState.newNotificationCount > 0"
          class="notification-count"
          ref="notificationCount"
        >
          {{ notificationState.newNotificationCount }}
        </div>
        <transition name="menu">
          <div
            class="menu notifications"
            v-if="notificationMenuOpen"
            :class="{ empty: userHasNoNotifications }"
          >
            <SmallLoadingSpinner v-if="loadingNotifications" class="notification-loading-spinner" />
            <!-- TODO: we should get an ID here for the key, but date works -->
            <Notification
              v-for="notification in notifications"
              :key="notification.date"
              :link="notification.link"
              :message="notification.message"
              :date="notification.date"
              @linkClicked="handleNotificationClicked(notification.link)"
            />
            <div v-if="userHasNoNotifications">You have no notifications</div>
            <MysticalButton
              v-else
              @click="handleSeeAllNotificationsClicked()"
              label="See all"
            ></MysticalButton>
          </div>
        </transition>
      </div>
      <div
        class="user-menu-container header-item"
        @mouseenter="handleUserMenuMouseOver()"
        @mouseleave="handleUserMenuMouseOut()"
      >
        <img
          class="header-icon user-menu-icon"
          src="./assets/user-solid.svg"
          alt="User icon"
          :title="`Hi, ${$username}!`"
        />
        <transition name="menu">
          <div class="menu" v-if="userMenuOpen">
            <MysticalButton to="/friends" label="Friends"></MysticalButton>
            <MysticalButton @click="$logout()" label="Log out"></MysticalButton>
          </div>
        </transition>
      </div>
    </header>
    <div id="content-container"
      @touchstart="handleContentTouch($event)"
      @touchend="handleContentTouch($event)"
      @touchmove="handleContentTouch($event)"
    >
      <nav>
        <router-link to="/" class="nav-link">
          <div class="active-icon" aria-hidden="true"></div>
          <img src="./assets/pencil-alt-solid.svg" />
          Active
        </router-link>
        <router-link to="/stories/completed" class="nav-link">
          <div class="active-icon" aria-hidden="true"></div>
          <img src="./assets/book-solid.svg" />
          Completed
        </router-link>
        <router-link to="/create-story" class="nav-link">
          <div class="active-icon" aria-hidden="true"></div>
          <img src="./assets/plus-solid.svg" />
          Create
        </router-link>
        <ModalButton
          class="add-friend"
          ariaLabel="Send a friend request!"
          buttonTitle="Send a friend request!"
          title="Send a friend request"
          :onOkClicked="sendFriendRequest.bind(this, $username, friendRequestUsername)"
          :successMessage="`Friend request sent to '${friendRequestUsername}'!`"
          :iconType="'user-plus-solid'"
          okButtonLabel="Send"
          :okDisabled="friendRequestSendDisabled"
          :focusCancelOnRender="false"
          :hideErrors="true"
          >Who would you like to add?
          <MysticalInput
            :isBlankable="false"
            blankMessage="We just need a username to send the request to 😅"
            v-model.trim="friendRequestUsername"
            :darkTheme="true"
            :asyncValidationFnc="
              validateFriendRequestUsername.bind(this, $username, friendRequestUsername)
            "
            @validationSucceeded="handleFriendRequestValidationSucceeded()"
            @validationFailed="handleFriendRequestValidationFailed()"
            :focusOnRender="true"
          />
        </ModalButton>
      </nav>
      <main>
        <router-view v-slot="{ Component }" :key="notificationKey">
          <transition name="fade" mode="out-in">
            <component :is="Component"></component>
          </transition>
        </router-view>
      </main>
      <Modal
        title="Whoa whoa whoa!"
        :open="unsavedChangesStateStore.state.displayUnsavedChangesPrompt"
        @okButtonClicked="handleUnsavedChangesOkClicked()"
        @cancelButtonClicked="handleUnsavedChangesCancelClicked()"
      >
        You have unsaved changes. Are you sure you want to continue?</Modal
      >
      <footer>© {{ new Date().getFullYear() }} Mystical Object (alpha)</footer>
    </div>
  </div>
</template>

<script>
import MysticalButton from './components/MysticalButton.vue';
import ModalButton from './components/ModalButton.vue';
import Modal from './components/Modal.vue';
import MysticalInput from './components/MysticalInput.vue';
import SmallLoadingSpinner from './components/SmallLoadingSpinner.vue';
import Notification from './components/Notification.vue';
import clickStateStore from './services/clickStateStore';
import unsavedChangesStateStore from './services/unsavedChangesStateStore';
import { sendFriendRequest, validateFriendRequestUsername } from './services/friendsService';
import notificationStateStore from './services/notificationStateStore';
import { getNotifications } from './services/notificationsService';
import { showError } from './services/toastService';

export default {
  name: 'App',
  components: {
    MysticalButton,
    Modal,
    ModalButton,
    MysticalInput,
    SmallLoadingSpinner,
    Notification,
  },
  data() {
    return {
      userMenuHover: false,
      notificationMenuHover: false,
      clickState: clickStateStore.state,
      sendFriendRequest,
      friendRequestUsername: '',
      validateFriendRequestUsername,
      friendRequestSendDisabled: true,
      unsavedChangesStateStore,
      notificationState: notificationStateStore.state,
      notifications: [],
      numberOfNotificationsToDisplay: 5,
      loadingNotifications: false,
      notificationKey: null,
    };
  },
  watch: {
    // TODO: there's got to be a better way...
    'notificationState.newNotificationCount'() {
      this.$refs.notificationCount.animate(
        [{ transform: 'scale(1)' }, { transform: 'scale(1.5)' }, { transform: 'scale(1)' }],
        {
          duration: 800,
          easing: 'ease',
        }
      );
    },
    notificationMenuOpen(newValue) {
      if (newValue) {
        this.handleNotificationsMenuOpened();
      }
    },
  },
  computed: {
    // TODO: Having two hoverable/pinnable menus causes a little duplication here. They could be pulled out into components
    userMenuOpen() {
      return !this.notificationMenuOpen && (this.userMenuHover || this.userMenuPinned);
    },
    userMenuPinned() {
      return this.clickState.lastElementClicked?.classList.contains('user-menu-icon');
    },
    notificationMenuOpen() {
      return !this.userMenuOpen && (this.notificationMenuHover || this.notificationMenuPinned);
    },
    notificationMenuPinned() {
      return this.clickState.lastElementClicked?.classList.contains('notification-menu-icon');
    },
    userHasNoNotifications() {
      return !this.loadingNotifications && this.notifications.length === 0;
    },
  },
  methods: {
    handleUserMenuMouseOver() {
      this.userMenuHover = true;
    },
    handleUserMenuMouseOut() {
      this.userMenuHover = false;
    },
    handleNotificationMenuMouseOver() {
      this.notificationMenuHover = true;
    },
    handleNotificationMenuMouseOut() {
      this.notificationMenuHover = false;
    },
    handleClick(event) {
      clickStateStore.setLastElementClicked(event.target);
    },
    handleFriendRequestValidationSucceeded() {
      this.friendRequestSendDisabled = false;
    },
    handleFriendRequestValidationFailed() {
      this.friendRequestSendDisabled = true;
    },
    handleUnsavedChangesOkClicked() {
      this.unsavedChangesStateStore.state.nextRoute();
      this.unsavedChangesStateStore.hideUnsavedChangesPrompt();
      this.unsavedChangesStateStore.setUnsavedChanges(false);
    },
    handleUnsavedChangesCancelClicked() {
      this.unsavedChangesStateStore.state.nextRoute(false);
      this.unsavedChangesStateStore.hideUnsavedChangesPrompt();
    },
    handleNotificationsMenuOpened() {
      this.loadingNotifications = true;

      getNotifications(this.$username, 0, this.numberOfNotificationsToDisplay)
        .then(response => {
          this.notifications = response.content;
          notificationStateStore.clearNotificationCount();
        })
        .catch(() => {
          showError("We couldn't get your notifications 😔");
        })
        .finally(() => {
          this.loadingNotifications = false;
        });
    },
    // When a notification is clicked, we need to check whether the notification's link matches the current route,
    // since we'll still want to refresh the router view if this is the case, and the vue-router doesn't have any
    // FREAKING way to respond to routes to the same location, which is "working as designed", even though this is a common
    // use case and yes I'm annoyed about it. We set a randomized key for the router view so it refreshes.
    handleNotificationClicked(link) {
      this.notificationMenuHover = false;

      if (link === window.location.pathname) {
        this.notificationKey = Math.random();
      }

      this.$router.push(link);
    },
    handleSeeAllNotificationsClicked() {
      this.notificationMenuHover = false;

      this.$router.push('/notifications');
    },
    handleContentTouch(event) {
      // If the user is touching a canvas, don't scroll the page
      if (event.target.localName === 'canvas') {
        event.preventDefault();
      }
    }
  },
  props: ['keycloak'],
};
</script>
<style lang="scss">
@import './common/_color_palette';
@import './common/_animations';
@import './common/_common';
@import './common/_z-layers';

:root {
  font-size: 10px;
  color: var(--color-white);
}
html,
body,
#app,
#app-wrapper {
  height: 100%;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: 'Nunito', sans-serif;
  background-color: var(--color-purple);
  overflow-x: hidden;
  margin: 0;

  #app-wrapper {
    display: flex;
    flex-direction: column;
    min-height: max-content;
    height: 100%;

    h1 {
      font-size: 4rem;
      text-align: center;
      margin: 0 1rem;
    }
    h2 {
      font-size: 2.5rem;
    }
    h3 {
      font-size: 1.8rem;
    }
    a {
      color: var(--color-white);
      text-decoration: none;
    }
    header {
      background-color: var(--color-purple-darken-1);
      font-size: 3rem;
      font-family: 'Modak', Sans-serif;
      color: white;
      display: flex;
      align-items: center;
      padding-right: 3rem;
      z-index: var(--z-header);
      position: fixed;
      width: 100vw;
      top: 0;
      box-shadow: var(--dark-shadow);

      .header-item {
        height: 5.5rem;
      }
      .header-icon {
        opacity: 0.7;
        height: 2.5rem;
        cursor: pointer;
        transition: opacity 200ms ease, transform 400ms ease;
      }
      .header-icon:hover {
        opacity: 1;
        transform: scale(1.2);
      }
      .notifications {
        margin-left: auto;
        position: relative;
        display: flex;
        align-items: center;

        .notification-count {
          font-family: Nunito;
          position: absolute;
          top: 0.7rem;
          font-size: 1.5rem;
          background: var(--color-red);
          padding: 0.5rem;
          border-radius: 2rem;
          height: 2rem;
          left: 1.3rem;
          display: flex;
          align-items: center;
          z-index: var(--z-notification-count);
          pointer-events: none;
        }
      }
      .home-link-container {
        position: relative;

        #logo,
        #logo-cover {
          font-size: 4rem;
          width: 6rem;
          background-color: var(--color-purple-darken-2);
          text-align: center;
          cursor: pointer;
        }
        #logo > #logo-text {
          color: transparent;
          background: var(--gradient-rainbow);
          background-clip: text;
          animation: rainbow_animation 6s linear infinite;
          background-size: 400% 100%;
        }
        #logo-cover {
          position: absolute;
          top: 0;
          background-color: transparent;
        }
        #logo-cover:hover > span {
          clip-path: circle(0% at 50% 50%);
        }
        #logo-cover > span {
          transition: clip-path 600ms ease;
          clip-path: circle(51% at 50% 50%);
          -webkit-text-stroke: 0.5px var(--color-purple-darken-1);
        }
      }
      .user-menu-container {
        position: relative;
        width: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1.5rem;
      }
      .menu {
        top: 5rem;
        right: -1rem;
        position: absolute;
        z-index: var(--z-user-menu);
        background: var(--color-purple-darken-2);
        padding: 1rem;
        border-radius: 1.5rem;
        width: 15rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.notifications {
          font-family: Nunito;
          font-size: 1.5rem;
          width: max-content;
          padding: 2rem;
          min-width: 20rem;
          min-height: 12rem;

          &.empty {
            min-height: unset;
          }

          .mystical-button {
            margin-top: 4rem;
          }

          .notification-loading-spinner {
            position: absolute;
          }
        }
        .mystical-button {
          padding: 1rem 1rem;
          width: 100%;
          font-size: 1.5rem;
          margin-top: 0;
          text-align: center;
          &:first-child {
            margin-bottom: 1rem;
          }
        }
      }
    }
    nav {
      font-size: 2.5rem;
      margin-top: 2rem;
      margin-bottom: 1rem;
      display: flex;
      .add-friend {
        transition: transform 400ms ease;
        &:hover {
          transform: scale(1.1);
        }
        img {
          width: 3rem;
          height: 3rem;
        }
      }
      a {
        color: var(--color-white);
        text-decoration: none;
        margin-right: 1rem;
        background-color: var(--color-purple);
        transition: filter 200ms ease;
        padding: 0 1rem;
        border-radius: 1.5rem;
        position: relative;
        &:not(:first-child) {
          margin-left: 1rem;
        }
        &:not(:first-child):last-of-type {
          margin-left: auto;
        }
        &:hover,
        &:focus {
          filter: brightness(1.1);
        }
        &:hover {
          img {
            animation: wobble 600ms linear infinite;
          }
        }
        img,
        .active-icon {
          top: 0.1rem;
        }
        img {
          filter: invert(1);
          opacity: 0.5;
          transition: filter 400ms ease, opacity 400ms ease;
          height: 2rem;
          width: 2rem;
          position: relative;
          left: 0.5rem;
          margin-right: 1rem;
        }
        .active-icon {
          display: inline-block;
          background-color: var(--color-creamsicle);
          border-radius: 50%;
          width: 3rem;
          height: 3rem;
          position: absolute;
          transition: transform 400ms ease, opacity 200ms ease;
          opacity: 0;
          transform: translateY(2rem) scaleY(1.2);
        }
        &.router-link-exact-active {
          background: white;
          color: var(--color-purple-darken-2);
          img {
            opacity: 1;
            filter: invert(0);
          }
          .active-icon {
            display: inline-block;
            background-color: var(--color-creamsicle);
            border-radius: 50%;
            width: 3rem;
            height: 3rem;
            position: absolute;
            opacity: 1;
            transform: translateY(0) scaleY(1);
          }
        }
      }
    }
  }
  #content-container {
    padding: 1rem 10vw;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: 5rem;

    main {
      flex-grow: 1;
      display: flex;
    }
    footer {
      text-align: center;
    }
  }
  .menu-enter-active,
  .menu-leave-active {
    transition: opacity 400ms ease, transform 400ms ease;
  }
  .menu-enter-from,
  .menu-leave-to {
    opacity: 0;
    transform: translateY(-1rem);
  }
}
.toasted.bubble {
  font-family: Nunito;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 400ms ease, transform 400ms ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
  transform: scale(0.95);
}

@media (max-width: 757px) {
  body #app-wrapper {
    h1 {
      font-size: 3rem;
    }
    h2 {
      font-size: 1.5rem;

      .mystical-button {
        font-size: 1.5rem;
        padding: 1rem 2rem;
      }
    }

    nav a {
      display: flex;
      flex-direction: column;
      font-size: 1.5rem;
      border-radius: 1rem;
      justify-content: center;
      align-items: center;
      height: 7rem;
      margin-bottom: 0.5rem;
      margin-right: 0;
      background: var(--color-purple-lighten-slightly);
      width: 29%;

      .active-icon {
        top: 0.65rem;
      }

      img {
        margin-bottom: 0.5rem;
      }

      &:not(:first-child):last-of-type {
        margin-left: 1rem;
      }
    }
  }

  .add-friend {
    margin-left: 1rem;
  }
}

@media (max-width: 600px) {
  body #content-container {
    padding: 1rem 5vw;
  }
}

@media (max-width: 500px) {
  body #app-wrapper header .menu.notifications {
    top: 6.2rem;
    right: 0;
    left: 0;
    border-radius: 0;
    position: fixed;
    width: 100vw;
  }
}

@media (max-width: 475px) {
  body #app-wrapper {
    h1 {
      font-size: 3rem;
    }

    h2 {
      font-size: 1.3rem;
    }
  }
}
</style>
