<template>
  <div class="button-wrapper">
    <router-link class="mystical-button" v-if="to" :to="to" :tabindex="tabindex">{{
      label
    }}</router-link>
    <button
      v-else
      class="mystical-button"
      @click="$emit('click')"
      :disabled="disabled || loading"
      ref="button"
      :title="title"
    >
      {{ label }}
    </button>
    <transition name="spinner">
      <SmallLoadingSpinner v-if="loading" />
    </transition>
  </div>
</template>

<script>
import SmallLoadingSpinner from './SmallLoadingSpinner.vue';

export default {
  name: 'MysticalButton',
  components: {
    SmallLoadingSpinner,
  },
  props: {
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    to: String,
    focused: Boolean,
    title: String,
  },
  emits: ['click'],
  computed: {
    tabindex() {
      return this.disabled ? -1 : 0;
    },
  },
  watch: {
    focused(newFocused) {
      if (newFocused) {
        this.$refs.button.focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-wrapper {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  transition: transform 400ms ease;

  .mystical-button {
    display: block;
    padding: 1rem 5rem;
    border-radius: 1rem;
    border: none;
    background: var(--color-purple-darken-2);
    color: white;
    font-family: Nunito;
    font-size: 2rem;
    cursor: pointer;
    transition: transform 400ms ease, filter 400ms ease, opacity 400ms ease;
    position: relative;
    z-index: 1;
    outline: none;

    &:not(:disabled) {
      &:hover,
      &:focus {
        transform: scale(1.1);
        filter: brightness(1.1);
      }
    }

    &:disabled {
      filter: saturate(0);
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .spinner {
    margin-left: 1rem;
  }
}

.spinnerenter-active,
.spinnerleave-active {
  transition: opacity 400ms ease, transform 400ms ease;
}

.spinnerenter-from,
.spinnerleave-to {
  opacity: 0;
  transform: translateX(-2rem);
}

@media(max-width: 400px) {
  .button-wrapper .mystical-button {
    font-size: 1.5rem;
  }
}

</style>
