<template>
  <div class="tab-content">
    <div v-show="showContents" class="tab-content-wrapper">
      <slot></slot>
    </div>
    <transition name="loading">
      <LoadingSpinner v-if="showLoadingIndicator" :errorOccurred="errorOccurred" />
    </transition>
  </div>
</template>

<script>
import LoadingSpinner from './LoadingSpinner.vue';

export default {
  name: 'TabContent',
  components: {
    LoadingSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    errorOccurred: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showLoadingIndicator() {
      return this.loading || this.errorOccurred;
    },
    showContents() {
      return !this.loading && !this.errorOccurred;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-content {
  box-shadow: var(--purple-shadow);
  background-color: var(--color-purple-lighten-slightly);
  padding: 2rem;
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;

  .loading-overlay-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: var(--z-loading-overlay);
    transition: transform 800ms ease;
    transform: translateY(100vh);

    &.visible {
      transform: translateY(0);
    }

    .loading-overlay {
      opacity: 0.95;
      background: #7331b0;
      height: 100%;
    }
  }

  .loading-leave-active,
  .loading-enter-active {
    transition: transform 400ms ease, opacity 400ms ease;
  }

  .loading-leave-to,
  .loading-enter {
    transform: translateX(10rem);
    opacity: 0;
  }
}

@media(max-width: 500px) {
  .tab-content {
    padding: 1rem;
  }
}
</style>
