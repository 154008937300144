import { fetchService } from './betterFetch';
import {
  getStoriesServicePath,
  getStoryRoundValidationPath,
  getStoriesForUserPath,
  getDeclineStoryPath,
  getIncompleteStoriesForUserWhereItsThisUsersTurnPath,
  getIncompleteStoriesForUserWhereItsNotThisUsersTurnPath,
  getCurrentChallengePath,
  getStoryRoundSubmissionPath,
  getAddStoryRoundIllustrationPath,
  getCompletedStoriesForUserPath,
  getStoryContentsPath,
  getStoryPlagiarismPath,
} from './urlService';

export async function getStories(username, pageNumber, pageSize) {
  return fetchService.fetch(getStoriesForUserPath(username, pageNumber, pageSize));
}

export async function getInProgressStoriesWhereItsThisUsersTurn(username, pageNumber, pageSize) {
  return fetchService.fetch(
    getIncompleteStoriesForUserWhereItsThisUsersTurnPath(username, pageNumber, pageSize)
  );
}

export async function getInProgressStoriesWhereItsNotThisUsersTurn(username, pageNumber, pageSize) {
  return fetchService.fetch(
    getIncompleteStoriesForUserWhereItsNotThisUsersTurnPath(username, pageNumber, pageSize)
  );
}

export async function createStory(storyBody) {
  return fetchService.fetch(getStoriesServicePath(), {
    method: 'POST',
    body: storyBody,
  });
}

export async function declineStory(username, storyId) {
  return fetchService.fetch(getDeclineStoryPath(storyId, username), {
    method: 'DELETE',
  });
}

export async function getStoryChallenge(storyId) {
  return fetchService.fetch(getCurrentChallengePath(storyId));
}

export async function validateChallengeSubmission(submission, storyId) {
  return fetchService.fetch(getStoryRoundValidationPath(storyId), {
    method: 'POST',
    body: {
      submission,
    },
  });
}

export async function submitRound(submission, storyId) {
  return fetchService.fetch(getStoryRoundSubmissionPath(storyId), {
    method: 'PUT',
    body: {
      submission,
    },
  });
}

export async function submitImage(imageFile, storyId) {
  const formData = new FormData();
  formData.append('image', imageFile, 'submission.png');

  return fetchService.fetch(getAddStoryRoundIllustrationPath(storyId), {
    method: 'POST',
    body: formData,
    multipart: true,
  });
}

export async function getCompletedStories(username, pageNumber, pageSize) {
  return fetchService.fetch(getCompletedStoriesForUserPath(username, pageNumber, pageSize));
}

export async function getStoryContents(storyId) {
  return fetchService.fetch(getStoryContentsPath(storyId));
}

export async function plagiarize() {
  return fetchService.fetch(getStoryPlagiarismPath());
}

export function getFormattedParticipantsList(users) {
  const participantsCommaSeparated = users.map(p => p.id.username).join(', ');
  const andIndex = participantsCommaSeparated.lastIndexOf(', ');

  return `${participantsCommaSeparated.slice(0, andIndex)} and ${participantsCommaSeparated.slice(
    andIndex + 2
  )}`;
}
