<template>
  <label
    :class="{ 'dark-theme': darkTheme, disabled: disabled }"
    @keyup.up="handleSuggestionUp()"
    @keyup.down="handleSuggestionDown()"
  >
    <div class="title-icon-wrapper">
      {{ label }}
      <transition name="icon">
        <img
          v-show="error && label"
          src="../assets/exclamation-circle-solid.svg"
          class="icon"
          alt="Error icon"
        />
      </transition>
    </div>
    <div class="input-container">
      <div class="chip-container">
        <transition-group name="chip" mode="out-in">
          <div class="chip" v-for="suggestion in selectedSuggestions" :key="suggestion">
            <button
              class="remove-selected"
              @click="removeSelection(suggestion)"
              :aria-label="`Remove ${suggestion}`"
            >
              X
            </button>
            {{ suggestion }}
          </div>
        </transition-group>
      </div>
      <!--We can't use v-model here because it doesn't work for live searches-->
      <input
        type="text"
        @input="handleInput($event)"
        @focus="handleFocus()"
        @blur="handleBlur($event)"
        :value="typedValue"
        @keyup.enter="handleEnterPressed()"
        :required="isBlankable"
        ref="input"
        :disabled="disabled"
      />
      <transition name="validating" mode="out-in">
        <SuccessIcon v-if="!error && asyncValidationSuccessful" />
        <div
          v-else-if="validatingAsync"
          class="async-validation-loading"
          aria-label="Validating your input"
        >
          <SmallLoadingSpinner :darkTheme="true" />
        </div>
      </transition>
      <transition name="suggestions">
        <div v-if="showSuggestions && suggestionsToShow.length > 0" class="suggestions">
          <button
            class="suggestion-btn"
            v-for="suggestion in suggestionsToShow"
            :key="suggestion"
            @click="handleSuggestionClick(suggestion)"
            @blur="handleBlur($event)"
            :ref="setSuggestionButtonRef"
          >
            {{ suggestion }}
          </button>
        </div>
      </transition>
    </div>
    <div class="underline" aria-hidden="true" :class="{ error: error, focused: focused }"></div>
    <transition name="message">
      <p class="message" v-if="error">{{ errorMessage }}</p>
    </transition>
  </label>
</template>

<script>
import { nextTick } from 'vue';
import SmallLoadingSpinner from './SmallLoadingSpinner.vue';
import SuccessIcon from './SuccessIcon.vue';

const DEBOUNCE_TIME = 1000;

// This component is getting pretty big. It might be a good idea to start composing smaller components
// instead if this starts to grow more.
export default {
  name: 'MysticalInput',
  data() {
    return {
      error: false,
      errorMessage: '',
      focused: false,
      selectedSuggestions: [],
      suggestionsToShow: [],
      showSuggestions: false,
      typedValue: '',
      modelValue: '',
      asyncErrorMessage: '',
      asyncErrorOccurred: false,
      asyncValidationSuccessful: false,
      debounceTimerId: null,
      validatingAsync: false,
      dirty: false,
      suggestionButtonEls: [],
      currentSuggestionFocusedIndex: null,
    };
  },
  components: {
    SmallLoadingSpinner,
    SuccessIcon,
  },
  emits: ['update:modelValue', 'validationSucceeded', 'validationFailed'],
  props: {
    label: String,
    isBlankable: {
      type: Boolean,
      default: true,
    },
    blankMessage: {
      type: String,
      default: "This field can't be left blank",
    },
    suggestions: {
      type: Array,
      default() {
        return [];
      },
    },
    darkTheme: Boolean,
    asyncValidationFnc: Function,
    focusOnRender: Boolean,
    disabled: Boolean,
  },
  computed: {
    suggestionsNotChosen() {
      return this.suggestions.filter(suggestion => !this.selectedSuggestions.includes(suggestion));
    },
  },
  unmounted() {
    this.clearDebounceTimer();
  },
  mounted() {
    if (this.focusOnRender) {
      nextTick().then(() => {
        this.$refs.input.focus();
      });
    }
  },
  beforeUpdate() {
    this.suggestionButtonEls = [];
  },
  methods: {
    isBlank() {
      return this.typedValue.trim().length === 0;
    },
    isBlankAndNoSuggestionSelected(value) {
      return this.isBlank(value) && this.selectedSuggestions.length === 0;
    },
    validate() {
      const blankErrorMessage =
        !this.isBlankable && this.isBlankAndNoSuggestionSelected(this.typedValue)
          ? this.blankMessage
          : null;

      if (blankErrorMessage) {
        this.setErrorAndMessage(blankErrorMessage);
      } else if (this.asyncErrorOccurred) {
        this.setErrorAndMessage(this.asyncErrorMessage);
      } else {
        this.error = false;
        this.$emit('validationSucceeded');
      }
    },
    setErrorAndMessage(message) {
      this.error = true;
      this.errorMessage = message;

      this.$emit('validationFailed', message);
    },
    clearDebounceTimer() {
      if (this.debounceTimerId) {
        clearTimeout(this.debounceTimerId);
      }
    },
    handleInput(event) {
      this.currentSuggestionFocusedIndex = null;
      this.typedValue = event.target.value;

      this.dirty = true;

      if (this.asyncValidationFnc) {
        this.initiateDebouncedValidation();
      } else {
        this.validate();
      }

      if (this.typedValue.trim().length === 0) {
        this.asyncErrorOccurred = false;
      }

      if (this.suggestions.length > 0) {
        this.showSuggestions = true;
        this.currentSuggestionFocusedIndex = null;
        this.suggestionsToShow = this.suggestionsNotChosen.filter(suggestion =>
          suggestion.startsWith(this.typedValue.toLowerCase())
        );
      } else {
        this.modelValue = this.typedValue;
        this.$emit('update:modelValue', this.typedValue);
      }
    },
    initiateDebouncedValidation() {
      this.asyncValidationSuccessful = false;
      this.asyncErrorOccurred = false;
      this.clearDebounceTimer();

      this.debounceTimerId = setTimeout(() => {
        this.performAsyncValidation(this.value);
      }, DEBOUNCE_TIME);
    },
    handleFocus() {
      this.focused = true;

      if (this.isBlank(this.typedValue)) {
        this.showSuggestions = true;
        this.currentSuggestionFocusedIndex = null;
        this.suggestionsToShow = this.suggestionsNotChosen;
      }
    },
    handleBlur(event) {
      if (!event.relatedTarget || !event.relatedTarget.classList.contains('suggestion-btn')) {
        this.showSuggestions = false;
        this.focused = false;

        if (this.dirty) {
          this.validate();
        }
      }
    },
    updateValueWithSuggestions() {
      this.modelValue = this.selectedSuggestions;
      this.$emit('update:modelValue', this.modelValue);
    },
    handleSuggestionClick(suggestion) {
      this.selectedSuggestions = this.selectedSuggestions.concat([suggestion]);

      this.typedValue = '';

      this.suggestionsToShow = this.suggestionsNotChosen;
      this.showSuggestions = false;
      this.validate();
      this.updateValueWithSuggestions();
    },
    removeSelection(valueToRemove) {
      this.selectedSuggestions = this.selectedSuggestions.filter(
        suggestion => suggestion !== valueToRemove
      );
      this.updateValueWithSuggestions();
    },
    handleEnterPressed() {
      if (this.suggestionsToShow && this.suggestionsToShow.length > 0) {
        this.handleSuggestionClick(this.suggestionsToShow[0]);
      }
    },
    performAsyncValidation() {
      this.validatingAsync = true;

      this.asyncValidationFnc(this.value)
        .then(() => {
          this.asyncErrorOccurred = false;
          this.asyncValidationSuccessful = true;
        })
        .catch(error => {
          this.asyncErrorOccurred = true;
          this.asyncValidationSuccessful = false;
          this.asyncErrorMessage = error.message;
        })
        .finally(() => {
          this.validatingAsync = false;
          this.validate();
        });
    },
    setSuggestionButtonRef(el) {
      if (el) {
        this.suggestionButtonEls.push(el);
      }
    },
    incrementCurrentSuggestionFocusedIndex() {
      if (
        this.currentSuggestionFocusedIndex === null ||
        this.currentSuggestionFocusedIndex === 0
      ) {
        this.currentSuggestionFocusedIndex = this.suggestionsToShow.length - 1;
      } else {
        this.currentSuggestionFocusedIndex -= 1;
      }
    },
    decrementCurrentSuggestionFocusedIndex() {
      if (
        this.currentSuggestionFocusedIndex === null ||
        this.currentSuggestionFocusedIndex === this.suggestionsToShow.length - 1
      ) {
        this.currentSuggestionFocusedIndex = 0;
      } else {
        this.currentSuggestionFocusedIndex += 1;
      }
    },
    updateCurrentSuggestionFocusedIndex(updateFnc) {
      if (this.suggestionButtonEls.length > 0) {
        updateFnc();
        this.suggestionButtonEls[this.currentSuggestionFocusedIndex].focus();
      }
    },
    handleSuggestionUp() {
      this.updateCurrentSuggestionFocusedIndex(this.incrementCurrentSuggestionFocusedIndex);
    },
    handleSuggestionDown() {
      this.updateCurrentSuggestionFocusedIndex(this.decrementCurrentSuggestionFocusedIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  display: block;
  font-size: 2rem;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 4rem;

  .input-container {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .chip-container {
      margin-right: 1rem;

      .chip {
        background-color: var(--color-purple-darken-1);
        border-radius: 2.5rem;
        padding: 0.3rem 1.5rem 0.3rem 0.2rem;
        font-size: 1.5rem;
        display: inline-block;

        &:not(:first-child) {
          margin-left: 0.5rem;
        }
      }

      .remove-selected {
        background: none;
        border: none;
        outline: none;
        color: white;
        margin-right: 0.2rem;
        opacity: 0.7;
        cursor: pointer;
        transition: opacity 400ms ease;

        &:hover {
          opacity: 1;
        }
      }
    }

    input {
      flex-grow: 1;
      height: 3rem;
      background: none;
      border: none;
      color: white;
      font-size: 1.7rem;
      outline: none;
    }

    .suggestions {
      position: absolute;
      top: 3.8rem;
      background: var(--color-purple-darken-1);
      padding: 1rem;
      border-radius: 1rem;
      z-index: 1;
      box-shadow: var(--purple-shadow);
      display: flex;
      flex-direction: column;
      max-height: 50vh;

      .suggestion-btn {
        min-width: 10rem;
        font-size: 1.8rem;
        background: var(--color-purple-darken-1);
        color: white;
        border: none;
        margin: 1rem 0;
        transition: filter 400ms ease;
        cursor: pointer;
        border-radius: 2.5rem;
        padding: 0.8rem;
        outline: none;

        &:hover,
        &:focus {
          filter: brightness(1.1);
        }
      }
    }

    img.spinner,
    .async-validation-success-indicator {
      width: 2rem;
      height: 2rem;
    }

    .async-validation-success-indicator {
      position: relative;
      top: 0.3rem;
    }
  }

  .underline {
    height: 0.2rem;
    background-color: var(--color-creamsicle);
    z-index: 1;
    width: 100%;
    border-radius: 0.3rem;
    opacity: 0.5;
    filter: brightness(2);
    transition: opacity 400ms ease, transform 400ms ease, filter 400ms ease;

    &.focused,
    &.error {
      transform: scaleY(1.1);
      opacity: 1;
      filter: brightness(1);
    }

    &.error {
      filter: brightness(1) saturate(10);
    }
  }

  &.disabled {
    input,
    .underline {
      opacity: 0.2;
    }
  }

  .message {
    margin: 0.8rem 0 0 0;
    font-size: 1.2rem;
    position: absolute;
  }

  .message-enter-active,
  .message-leave-active {
    transition: opacity 400ms ease, transform 400ms ease;
  }

  .message-enter-from,
  .message-leave-to {
    opacity: 0;
    transform: translateX(-1rem);
  }

  .title-icon-wrapper {
    display: flex;
    align-items: center;

    .icon {
      width: 2.2rem;
      margin-left: 0.7rem;
      position: relative;
      bottom: 1px;
    }
  }

  .icon-enter-active,
  .icon-leave-active {
    transition: opacity 400ms ease, transform 400ms ease;
  }

  .icon-enter-from,
  .icon-leave-to {
    opacity: 0;
    transform: scale(0);
  }

  .icon-enter-to {
    transform: scale(1);
    opacity: 1;
  }

  .suggestions-enter-active,
  .suggestions-leave-active {
    transition: opacity 400ms ease, transform 400ms ease;
    transform-origin: top;
  }

  .suggestions-enter-from,
  .suggestions-leave-to {
    opacity: 0;
    transform: translateY(-1rem) scaleY(0.3);
  }

  .chip-enter-active,
  .chip-leave-active {
    transition: opacity 400ms ease, transform 400ms ease;
  }

  .chip-enter-from,
  .chip-leave-to {
    opacity: 0;
    transform: translateY(1rem);
  }

  .validating-enter-active,
  .validating-leave-active {
    transition: opacity 400ms ease, transform 400ms ease;
  }

  .validating-enter-from,
  .validating-leave-to {
    opacity: 0;
    transform: translateY(1rem);
  }

  &.dark-theme {
    .underline {
      filter: brightness(0);

      &.focused {
        filter: brightness(1);
      }

      &.error {
        filter: brightness(1) saturate(10);
      }
    }

    input {
      color: var(--color-purple-darken-2);
    }
  }
}
</style>
