<template>
  <button
    :aria-label="ariaLabelOrTitle"
    :title="title"
    @click.stop.prevent="$emit('clicked', $event)"
    :disabled="disabled"
  >
    <!-- TODO: is there a better way of doing this? Dynamically binding to src doesn't appear to work.-->
    <img
      v-if="iconType === 'times-solid'"
      src="../assets/times-solid.svg"
      :alt="`${buttonTitle} icon`"
    />
    <img
      v-else-if="iconType === 'user-plus-solid'"
      src="../assets/user-plus-solid.svg"
      :alt="`${buttonTitle} icon`"
    />
    <img
      v-else-if="iconType === 'check-solid'"
      src="../assets/check-solid.svg"
      :alt="`${buttonTitle} icon`"
    />
    <img
      v-else-if="iconType === 'undo-solid'"
      src="../assets/undo-solid.svg"
      :alt="`${buttonTitle} icon`"
    />
    <p v-else>No icon set</p>
  </button>
</template>

<script>
export default {
  name: 'IconButton',
  emits: ['clicked'],
  props: {
    ariaLabel: String,
    buttonTitle: String,
    iconType: {
      type: String,
      default: 'times-solid',
    },
    disabled: Boolean,
  },
  computed: {
    ariaLabelOrTitle() {
      return this.ariaLabel || this.title;
    },
    title() {
      return this.disabled ? '' : this.buttonTitle;
    }
  },
};
</script>

<style lang="scss" scoped>
button {
  background: none;
  border: none;
  outline: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 400ms ease;
  position: relative;
  top: 0.15rem;

  &:disabled {
    cursor: not-allowed;
    opacity: .2;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  img {
    width: 2rem;
    height: 2rem;
  }
}
</style>
