<template>
  <div class="infinite-scroll" ref="wrapper">
    <slot></slot>
    <transition name="spinner" mode="out-in">
      <div v-if="loading" class="loading-spinner" aria-label="loading">
        <SmallLoadingSpinner />
      </div>
      <div v-else-if="resultsAreEmpty && emptyMessage" class="empty-message">
        {{ emptyMessage }}
      </div>
    </transition>
  </div>
</template>

<script>
import SmallLoadingSpinner from './SmallLoadingSpinner.vue';
import { showError } from '../services/toastService';

const PAGE_SIZE = 25;

export default {
  name: 'InfiniteScroll',
  props: {
    getFnc: Function,
    errorMessage: String,
    showInitialLoadingIndicator: {
      type: Boolean,
      default: true,
    },
    emptyMessage: String,
    // TODO: This property sets the name of the property to fetch from the result (since paginated results
    // hold their actual contents in the 'content' property, with the remaining top-level properties
    // set aside for metadata. This is here due to a quirk paginating friends, and should be removed
    // when that's fixed)
    resultProperty: {
      type: String,
      default: 'content',
    },
  },
  components: {
    SmallLoadingSpinner,
  },
  data() {
    return {
      nextPage: 0,
      scrollHandler: null,
      dataSet: [],
      loading: false,
    };
  },
  mounted() {
    this.getNextPage();
  },
  unmounted() {
    this.removeScrollListener();
  },
  methods: {
    getNextPage() {
      if (!(this.nextPage === 0 && !this.showInitialLoadingIndicator)) {
        this.loading = true;
      }

      this.getFnc(this.$username, this.nextPage, PAGE_SIZE)
        .then(result => {
          this.loading = false;
          this.handleLoadSuccess(result);
        })
        .catch(error => {
          showError(this.errorMessage);
          this.$emit('errorOccurred', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleLoadSuccess(response) {
      const result = this.resultProperty ? response[this.resultProperty] : response;

      this.dataSet = this.dataSet.concat(result);

      this.$emit('dataChange', this.dataSet);
      this.nextPage += 1;

      if (this.resultProperty && !response.last) {
        this.setUpScrollListener();
      }
    },
    setUpScrollListener() {
      this.scrollHandler = this.handleScroll.bind(this);

      window.addEventListener('scroll', this.scrollHandler);
    },
    handleScroll() {
      if (this.windowScrolledToBottomOfElement()) {
        this.removeScrollListener();
        this.getNextPage();
      }
    },
    windowScrolledToBottomOfElement() {
      return window.innerHeight >= this.$refs.wrapper.getBoundingClientRect().bottom;
    },
    removeScrollListener() {
      window.removeEventListener('scroll', this.scrollHandler);
    },
  },
  computed: {
    resultsAreEmpty() {
      return this.dataSet.length === 0 && this.nextPage === 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.infinite-scroll {
  display: flex;
  flex-direction: column;

  .empty-message {
    text-align: center;
    font-size: 1.5rem;
    text-align: center;
    background: var(--color-purple);
    padding: 1rem;
    border-radius: 1rem;
  }

  .loading-spinner {
    margin: 1rem auto 2rem auto;
    height: 3rem;
    max-height: 3rem;
  }
}

.spinner-enter-active,
.spinner-leave-active {
  transition: opacity 400ms ease, transform 800ms ease;
}

.spinner-enter-from,
.spinner-leave-to {
  opacity: 0;
  transform: translateY(-3rem);
}

.empty-message-enter-active,
.empty-message-leave-active {
  transition: opacity 400ms ease, transform 800ms ease;
}

.empty-message-enter-from,
.empty-message-leave-to {
  opacity: 0;
  transform: translateY(-3rem);
}

@media(max-width: 475px) {
  .infinite-scroll .empty-message {
    font-size: 1.2rem;
  }
}
</style>
