import { createApp, h } from 'vue';
import { Client } from '@stomp/stompjs';
import Keycloak from 'keycloak-js';
import Toast, { POSITION } from 'vue-toastification';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { fetchService } from './services/betterFetch';
import { exists, create } from './services/userService';
import { getUnreadNotificationsCount } from './services/notificationsService';
import notificationStateStore from './services/notificationStateStore';

import 'vue-toastification/dist/index.css';

const keycloakInitOptions = {
  url: process.env.VUE_APP_AUTH_SERVER_URL,
  realm: process.env.VUE_APP_AUTH_SERVER_REALM,
  clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
  onLoad: 'login-required',
};

let websocketClient;

const keycloak = Keycloak(keycloakInitOptions);

const toastOptions = {
  position: POSITION.BOTTOM_CENTER,
  timeout: 10000,
  hideProgressBar: true,
};

function setUpApp(username) {
  const app = createApp({
    render: () => h(App, { props: { keycloak } }),
  });

  app.use(Toast, toastOptions).use(router);

  app.config.globalProperties.$username = username;
  app.config.globalProperties.$logout = keycloak.logout;

  return app;
}

async function createUserIfNotExists(username) {
  const response = await exists(username);

  if (!response.created) {
    await create(username);
  }
}

async function setNotificationsCount(username) {
  const count = await getUnreadNotificationsCount(username);

  notificationStateStore.increaseNotificationCount(count);
}

async function setupWebsockets() {
  if (websocketClient) {
    await websocketClient.deactivate();
  }

  websocketClient = new Client({
    brokerURL: `${process.env.VUE_APP_BACKEND_WEBSOCKET_URL}?access_token=${keycloak.token}`,
  });
  websocketClient.onConnect = () => {
    websocketClient.subscribe('/user/queue/notifications', () => {
      notificationStateStore.increaseNotificationCount(1);
    });
  };
  websocketClient.activate();
}

function setupTokenRefresh() {
  setInterval(() => {
    keycloak
      .updateToken(process.env.VUE_APP_KEYCLOAK_MIN_VALIDITY)
      .then(refreshed => {
        if (refreshed) {
          fetchService.setToken(keycloak.token);

          console.info(`Token refreshed ${refreshed}`);
          setupWebsockets();
        }
      })
      .catch(() => {
        console.error('Failed to refresh token');
      });
  }, process.env.VUE_APP_KEYCLOAK_REFRESH_INTERVAL);
}

async function initializeApp() {
  const username = keycloak.idTokenParsed.preferred_username;

  fetchService.setToken(keycloak.token);

  await createUserIfNotExists(username);

  const app = setUpApp(username);

  await setNotificationsCount(username);

  await setupWebsockets();

  app.mount('#app');

  setupTokenRefresh();
}

keycloak
  .init({ onLoad: keycloakInitOptions.onLoad })
  .then(auth => {
    if (!auth) {
      window.location.reload();
    } else {
      initializeApp();
    }
  })
  .catch(error => {
    console.error(error);
  });
