<template>
  <div class="mystical-object">
    <div class="image-container">
      <img
        v-if="type === 'thinking'"
        src="../assets/thinking-face.svg"
        alt="Thinking face"
        class="face"
      />
      <img
        v-else-if="type === 'surprised'"
        src="../assets/surprised-face.svg"
        alt="Surprised face"
        class="face"
      />
      <img
        v-else-if="type === 'winking'"
        src="../assets/winking-face.svg"
        alt="Winking face"
        class="face"
      />
      <img v-else src="../assets/embarassed-face.svg" alt="Embarassed face" class="face" />
      <Icosphere />
    </div>
    <h1 v-if="message">{{ message }}</h1>
    <slot></slot>
  </div>
</template>

<script>
import Icosphere from './Icosphere.vue';

export default {
  name: 'MysticalObject',
  components: {
    Icosphere,
  },
  props: {
    message: String,
    type: String,
  },
};
</script>

<style lang="scss" scoped>
.mystical-object {
  position: relative;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 0;
    margin-top: 3rem;
  }

  .image-container {
    position: relative;
    display: flex;
    justify-content: center;

    .icosphere {
      width: 100%;
      max-width: 25rem;
      margin-bottom: 5rem;
    }

    .face {
      position: absolute;
      z-index: 1;
      top: 18%;
      width: 62%;
      max-width: 16rem;
    }
  }
}

@media (max-width: 500px) {
  .mystical-object {
    .image-container {
      .icosphere {
        max-width: 18rem;
      }
    }
  }
}
</style>
