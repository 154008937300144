import { reactive } from 'vue';

export default reactive({
  state: {
    unsavedChanges: false,
    displayUnsavedChangesPrompt: false,
    nextRoute: null,
  },

  setUnsavedChanges(value) {
    this.state.unsavedChanges = value;
  },
  displayUnsavedChangesPrompt() {
    this.state.displayUnsavedChangesPrompt = true;
  },
  hideUnsavedChangesPrompt() {
    this.state.displayUnsavedChangesPrompt = false;
  },
  setRouterNext(next) {
    this.state.nextRoute = next;
  },
});
