import { reactive } from 'vue';

export default {
  state: reactive({
    lastElementClicked: null,
  }),

  setLastElementClicked(element) {
    this.state.lastElementClicked = element;
  },
};
