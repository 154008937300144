<template>
  <teleport to="body">
    <div
      class="modal"
      :class="{ open: open }"
      role="dialog"
      aria-labelledby="modal-title"
      aria-describedby="modal-text"
    >
      <div class="modal-background" aria-hidden="true"></div>
      <div class="modal-box">
        <div id="modal-title">
          <span>{{ title }}</span>
          <transition name="spinner">
            <span v-show="showSpinner" class="spinner-wrapper"><SmallLoadingSpinner /></span>
          </transition>
        </div>
        <p id="modal-text">
          <slot v-if="!hideContents"></slot>
        </p>
        <transition name="error">
          <p v-if="errorMessage && !hideErrors" class="error-container">{{ errorMessage }}</p>
        </transition>
        <div class="button-container">
          <MysticalButton
            id="modal-ok"
            :label="okButtonLabel"
            :disabled="okButtonDisabled"
            @click="$emit('okButtonClicked')"
            :title="okButtonTitle"
          />
          <MysticalButton
            :label="cancelButtonLabel"
            id="modal-cancel"
            @click="$emit('cancelButtonClicked')"
            :disabled="cancelButtonDisabled"
            :focused="focusCancelButton"
          />
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import MysticalButton from './MysticalButton.vue';
import SmallLoadingSpinner from './SmallLoadingSpinner.vue';

export default {
  name: 'Modal',
  components: {
    MysticalButton,
    SmallLoadingSpinner,
  },
  emits: ['okButtonClicked', 'cancelButtonClicked'],
  props: {
    open: Boolean,
    title: String,
    showSpinner: Boolean,
    hideContents: Boolean,
    errorMessage: String,
    hideErrors: Boolean,
    disableOkButton: Boolean,
    disableCancelButton: Boolean,
    focusCancelButton: Boolean,
    okButtonLabel: {
      type: String,
      default: 'Okay',
    },
    cancelButtonLabel: {
      type: String,
      default: 'Cancel',
    },
    okButtonTitle: String,
    cancelButtonTitle: String,
  },
  computed: {
    okButtonDisabled() {
      return this.disableOkButton || this.showSpinner;
    },
    cancelButtonDisabled() {
      return this.disableCancelButton || this.showSpinner;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  justify-content: center;
  pointer-events: none;
  display: flex;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100vw;
  z-index: var(--z-modal);

  &.open {
    pointer-events: all;

    .modal-background {
      opacity: 0.6;
    }

    .modal-box {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .modal-box {
    z-index: var(--z-modal__content);
    background: white;
    border-radius: 1.5rem;
    padding: 2rem;
    position: fixed;
    box-shadow: 0px -2px 12px #321051;
    color: var(--color-purple-darken-2);
    overflow: hidden;
    border: 2px solid var(--color-white);
    opacity: 0;
    transform: translateY(4rem);
    transition: opacity 400ms ease, transform 400ms ease;
    justify-self: center;
    align-self: center;
    margin: 1rem;
    max-width: 50vw;

    #modal-title {
      background: var(--color-purple-darken-1);
      color: white;
      font-size: 2rem;
      margin: -2rem -2rem 0 -2rem;
      padding: 2rem 2rem 1rem 2rem;
      display: flex;

      .spinner-wrapper {
        margin-left: auto;

        img.spinner {
          height: 2rem;
          width: 2rem;
        }
      }
    }

    #modal-text {
      font-size: 1.5rem;
    }

    .error-container {
      padding: 1.5rem;
      background: var(--color-angry-red);
      color: var(--color-white);
      font-size: 1.5rem;
      font-weight: 600;
      border-radius: 1.5rem;
      margin-top: 1rem;
    }

    .button-container {
      display: flex;

      .button-wrapper {
        display: inline-block;

        &:nth-of-type(1) {
          margin-right: 1rem;
        }
      }

      #modal-ok {
        margin-left: auto;
      }
    }
  }

  .modal-background {
    opacity: 0;
    transition: opacity 400ms ease;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: black;
    z-index: var(--z-modal__background);
  }

  .spinner-enter-active,
  .spinner-leave-active {
    transition: opacity 400ms ease, transform 400ms ease;
  }

  .spinner-enter-from,
  .spinner-leave-to {
    opacity: 0;
    transform: translateX(3rem);
  }

  .error-enter-active,
  .error-leave-active {
    transition: opacity 400ms ease, transform 400ms ease;
  }

  .error-enter-from,
  .error-leave-to {
    opacity: 0;
    transform: translateX(-3rem);
  }
}

@media(max-width: 900px) {
  .modal .modal-box {
    max-width: 95vw;
  }
}
</style>
