<template>
  <div
    class="notification"
    :class="{ linked: link, light: lighterTheme }"
    @click="handleClicked($event)"
  >
    <div class="notification-icon">
      <img src="../assets/pencil-alt-solid.svg" alt="notification icon" />
    </div>
    <button v-if="link" class="notification-content">
      {{ message }}
      <span class="time">{{ getRelativeDate(date) }}</span>
    </button>
    <span v-else class="notification-content"
      >{{ message }} <span class="time">{{ getRelativeDate(date) }}</span></span
    >
  </div>
</template>

<script>
export default {
  name: 'Notification',
  functional: true,
  props: {
    link: String,
    date: String,
    message: String,
    lighterTheme: Boolean,
  },
  emits: ['linkClicked'],
  methods: {
    getRelativeDate(dateString) {
      const now = new Date();
      const then = new Date(dateString);
      const nowYear = now.getFullYear();
      const nowMonth = now.getMonth();
      const nowDate = now.getDate();
      const nowHours = now.getHours();
      const nowMinutes = now.getMinutes();
      const thenYear = then.getFullYear();
      const thenMonth = then.getMonth();
      const thenDate = then.getDate();
      const thenHours = then.getHours();
      const thenMinutes = then.getMinutes();

      let unit;
      let amount;

      if (thenYear < nowYear) {
        amount = nowYear - thenYear;
        unit = 'year';
      } else if (nowYear === thenYear && thenMonth < nowMonth) {
        amount = nowMonth - thenMonth;
        unit = 'month';
      } else if (nowMonth === thenMonth && thenDate < nowDate) {
        amount = nowDate - thenDate;
        unit = 'day';
      } else if (nowDate === thenDate && thenHours < nowHours) {
        amount = nowHours - thenHours;
        unit = 'hour';
      } else if (nowHours === thenHours && thenMinutes < nowMinutes) {
        amount = nowMinutes - thenMinutes;
        unit = 'minute';
      } else {
        return 'A few seconds ago';
      }

      const pluralization = amount > 1 ? 's' : '';

      return `${amount} ${unit}${pluralization} ago`;
    },
    handleClicked(event) {
      if (this.link) {
        this.$emit('linkClicked', event);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  margin-bottom: 1rem;
  padding: 1rem;
  cursor: default;
  width: 100%;
  display: flex;
  transition: background 400ms ease, transform 400ms ease, box-shadow 400ms ease;
  border-radius: 1rem;

  &.linked {
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      background: rgba(255, 255, 255, 0.05);
    }

    .notification-content {
      cursor: pointer;
    }
  }

  &:not(:first-of-type) {
    margin-top: 1rem;
  }

  .notification-icon {
    width: 3rem;
    height: 3rem;
    min-width: 3rem;
    background: var(--color-creamsicle);
    border-radius: 50%;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 2rem;
      height: 2rem;
      opacity: 0.7;
    }
  }

  .notification-content {
    display: flex;
    flex-direction: column;
    color: unset;
    background: none;
    border: none;
    font-size: 1.5rem;
    padding-left: 0;
    text-align: left;

    .time {
      font-size: 1.2rem;
      color: var(--color-purple);
      width: max-content;
      margin-top: 0.1rem;
    }
  }

  &:last-of-type {
    padding-bottom: 4rem;
  }

  &.light {
    background: var(--color-purple-darken-1);

    &.linked {
      &:hover,
      &:focus,
      &:active {
        background: var(--color-purple-darken-1);
        transform: scale(1.01);
        box-shadow: var(--purple-shadow);
      }
    }

    .time {
      color: var(--color-purple-lighten-1);
    }
  }
}
</style>
