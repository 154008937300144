import { fetchService } from './betterFetch';
import { getNotificationsForUserPath, getUnreadNotificationsCountForUserPath } from './urlService';

export function getNotifications(username, pageNumber, pageSize) {
    return fetchService.fetch(getNotificationsForUserPath(username, pageNumber, pageSize));
}

export function getUnreadNotificationsCount(username) {
  return fetchService.fetch(getUnreadNotificationsCountForUserPath(username));
}
