<template>
  <div class="container">
    <div class="image-container">
      <!-- TODO: this transition doesn't appear to be working -->
      <transition name="loading" mode="out-in">
        <div v-if="!errorOccurred" class="lamp-container" aria-hidden="true">
          <div class="lava">
            <div class="globule"></div>
            <div class="globule"></div>
            <div class="globule"></div>
          </div>
          <img class="shine" src="../assets/laval_lamp_shine.svg" />
          <img class="glass" src="../assets/laval_lamp_glass.svg" />
          <img class="base" src="../assets/laval_lamp_base.svg" />
        </div>
        <div v-else class="error" aria-hidden="true">
          <img class="error-face" src="../assets/embarassed-face.svg" />
          <Icosphere />
        </div>
      </transition>
    </div>

    <p class="message" :class="{ error: errorOccurred }" ref="message">
      {{ message }}<span v-if="!errorOccurred" class="ellipsis">...</span>
    </p>

    <!--Massive shoutouts to Lucas Bebber for the SVG filter idea here: https://css-tricks.com/gooey-effect/ -->
    <svg class="filter" xmlns="http://www.w3.org/2000/svg" version="1.1">
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
            result="goo"
          />
          <feBlend in="SourceGraphic" in2="goo" />
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
import Icosphere from './Icosphere.vue';

const MESSAGE_DELAY_RANGE = 2000;
const MESSAGE_DELAY_OFFSET = 3000;
let requestAnimationFrameId;

export default {
  name: 'LoadingSpinner',
  components: {
    Icosphere,
  },
  mounted() {
    this.randomizeMessage();
    this.randomizeMessageAtSomePoint();
  },
  beforeUpdate() {
    if (this.errorOccurred) {
      this.message = 'Erm... something went wrong...';
    }
  },
  props: {
    errorOccurred: Boolean,
  },
  data() {
    return {
      message: '',
      messages: [
        "Working through some writer's block",
        'Trying to remember the database credentials',
        'Fiddling about',
        'Catching up on some LGR',
        'Just really spacing out for a second',
        'Trying to get into the "zone"',
        'Multi-tasking ineffectively',
        "Trying to get the 1's and 0's in just the right spot",
        'Firing up the AeroPress',
        'Wondering about my future',
        'Turning it off and turning it back on',
        'Just kind of taking it all in',
        "Cleaning up some tabs I've had up for a while",
        'Becoming a team player',
        'Streamlining some deliverables',
        'Honestly not doing that much',
        'Kinda just hanging out',
        'Eating a crisp, fresh apple',
        'Reminiscing about high school',
        'Getting nostalgic about Newgrounds',
        'Briefly panicking',
        'Shrugging it off',
        'Doing some deep breathing',
        'Buying some time',
        "Humming a lil' tune",
        'Doing a tiny jig',
        'Honestly freaking out a bit',
        'Googling proper posture',
        'Thinking about doing some stretches in a few minutes',
        'Going to CSS-Tricks to remember how flex box works',
        'Reading the docs',
        'Wondering why I went to college',
        'Trying to remember what I was doing',
        'Trying to remember which Vue directive to use here',
        'Trying to remember the syntax for this weird JavaScript thing',
        'Debating the usefulness of TypeScript with somebody on the internet',
        'Heading to Stack Overflow real quick',
        "Let me level with you: I have no idea what's taking so long",
        'Hoping the server is still running',
      ],
    };
  },
  methods: {
    randomizeMessage() {
      const currentIndex = this.messages.indexOf(this.message);
      const randomOffset = Math.max(Math.floor(Math.random() * this.messages.length), 1);

      const randomIndex = currentIndex + randomOffset;

      const lastIndex = this.messages.length - 1;

      const randomIndexWrapped = randomIndex > lastIndex ? randomIndex - lastIndex : randomIndex;

      // TODO: clean this up
      this.$refs.message
        .animate(
          [
            { opacity: 1, transform: 'translateY(0)' },
            { opacity: 0, transform: 'translateY(2rem)' },
          ],
          { duration: 400, fill: 'forwards', easing: 'ease' }
        )
        .finished.then(() => {
          // In the event that this component is unmounted before this animation finishes,
          // we don't want to continue. Continuing after unmounted would result in an error
          // since this.$refs.message is no longer defined.
          if (this.$refs.message) {
            this.message = this.messages[randomIndexWrapped];

            this.$refs.message.animate(
              [
                { opacity: 0, transform: 'translateY(2rem)' },
                { opacity: 1, transform: 'translateY(0)' },
              ],
              {
                duration: 400,
                fill: 'forwards',
                easing: 'ease',
              }
            );
          }
        });
    },
    changeMessageIfEnoughTimeElapsed(start, timeRequired) {
      if (performance.now() - start > timeRequired) {
        this.randomizeMessage();
        this.randomizeMessageAtSomePoint();
      } else if (!this.errorOccurred) {
        window.cancelAnimationFrame(requestAnimationFrameId);

        requestAnimationFrameId = window.requestAnimationFrame(() => {
          this.changeMessageIfEnoughTimeElapsed(start, timeRequired);
        });
      }
    },
    randomizeMessageAtSomePoint() {
      const timeUntilNextMessage = Math.random() * MESSAGE_DELAY_RANGE + MESSAGE_DELAY_OFFSET;
      window.cancelAnimationFrame(requestAnimationFrameId);

      requestAnimationFrameId = window.requestAnimationFrame(start => {
        this.changeMessageIfEnoughTimeElapsed(start, timeUntilNextMessage);
      });
    },
  },
  unmounted() {
    window.cancelAnimationFrame(requestAnimationFrameId);
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  position: absolute;
  top: 0;

  .message {
    font-size: 2.5rem;
    animation: fade-in-slide-up 600ms ease;
    opacity: 0;

    & > .ellipsis {
      clip-path: inset(0 2rem 0 0);
      animation: ellipsis 1s linear infinite;
    }

    &.error {
      opacity: 1;
    }
  }

  .error {
    position: relative;
    animation: fade-in-slide-right 600ms ease;

    .icosphere {
      width: 25rem;
      margin-top: 5rem;
    }

    .error-face {
      position: absolute;
      z-index: 1;
      top: 8rem;
      width: 18rem;
      left: 4rem;
    }
  }

  .lamp-container {
    width: 20rem;
    height: 20rem;
    position: relative;
    animation: fade-in-slide-right 600ms ease;
    margin-top: 10rem;

    img,
    .lava {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .glass {
      z-index: 1;
    }
    .shine {
      z-index: 2;
    }
    .lava {
      mask-image: url('../assets/laval_lamp_glass.svg');
      mask-size: contain;
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: url('#goo');
    }
    .globule {
      background-color: #ffa99bff;
      border-radius: 50%;
      position: absolute;

      &:first-child {
        width: 2rem;
        height: 2rem;
        bottom: 69%;
        animation: globule_one_movement 15s ease infinite;
      }

      &:nth-child(2) {
        width: 1rem;
        height: 1rem;
        bottom: 36%;
        animation: globule_two_movement 10s ease infinite;
      }

      &:nth-child(3) {
        width: 1.5rem;
        height: 1.5rem;
        bottom: 36%;
        left: 50%;
        animation: globule_three_movement 12s ease infinite;
      }
    }
  }
}

.filter {
  height: 0;
}

@keyframes globule_one_movement {
  0% {
    transform: translateY(0) scaleY(1) scaleX(1);
  }

  50% {
    transform: translateY(7rem) scaleY(0.75) scaleX(1.25);
  }

  100% {
    transform: translateY(0) scaleY(1) scaleX(1);
  }
}

@keyframes globule_two_movement {
  0% {
    transform: translateY(0) scaleY(1);
  }

  50% {
    transform: translateY(-7rem) scaleY(1.5);
  }

  100% {
    transform: translateY(0) scaleY(1);
  }
}

@keyframes globule_three_movement {
  0% {
    transform: translateY(0) scaleY(1.5);
  }

  50% {
    transform: translateY(-7rem) translateX(-2rem) scaleY(1);
  }

  100% {
    transform: translateY(0) scaleY(1.5);
  }
}

@keyframes fade-in-slide-up {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-slide-right {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes ellipsis {
  0% {
    clip-path: inset(0 2rem 0 0);
  }
  33% {
    clip-path: inset(0 1rem 0 0);
  }
  66% {
    clip-path: inset(0 0.5 0 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}
</style>
